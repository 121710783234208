body {
  margin: 0;
  font-family: atypRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow: hidden;
}

/* to change the default styling in react routes a links*/
a {
  text-decoration: none;
}

img {
  display: block;
  margin: 'auto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before,
#root {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}

@font-face {
  font-family: atypLight;
  src: local('AtypDisplay-Light'),
    url('/public/assets/font-atyp/AtypDisplay-Light.ttf') format('truetype');
}

@font-face {
  font-family: atypMedium;
  src: local('AtypDisplay-Medium'),
    url('/public/assets/font-atyp/AtypDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: atypRegular;
  src: local('AtypDisplay-Regular'),
    url('/public/assets/font-atyp/AtypDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: atypSemibold;
  src: local('AtypDisplay-Semibold'),
    url('/public/assets/font-atyp/AtypDisplay-Semibold.ttf') format('truetype');
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
